@import '../../../styles/customMediaQueries.css';

.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 20px; */

  @media(--viewportMediumMax) {
    margin: 0 25px;
  }
}

.sliderContainer {
  width: 100%;
}

.sliderControls {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cardsWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.cardContainer {
  border: 1px solid #B2B2B2;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  flex: 1 1 250px;
  padding: 20px 15px;
  display: flex;
  margin-right: 20px;
  flex-direction: column;
}

.cardContainer:last-child {
  margin-right: 0;
}

.cardBody {
  margin-top: auto;
}

.priceIncluded {
  font-size: 20px;
  color: #4871F7;
  font-weight: 700;
}

.rfq {
  font-size: 20px;
  color: #4871F7;
  font-weight: 600;
  margin-right: 100px;
}

.priceLabel {
  color: #4A4A4A;
  font-size: 14px;
  font-weight: 500;
  flex: 1 1 50%;
}

.cardTitle {
  margin: 0;
  color: #4A4A4A;
  font-size: 14px;
  font-weight: 700;
}

.cardDescription {
  margin: 0;
  color: #4A4A4A;
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  margin-bottom: 30px;
}

.sliderBtn {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #D8DCE6;
  border: none;
  outline: none;
  cursor: pointer;
}

.attachmentsHeadline {
  font-weight: 700;
  font-size: 18px;
  color: #B0B0B5;

  @media(--viewportMediumMax) {
    margin: 0 25px 20px;
  }
}

.priceRow {
  margin-bottom: 10px;
  display: flex;
}

.price {
  font-size: 20px;
  color: #4871F7;
  flex: 1 1 50%;
}

.icon {
  margin-bottom: 3px;
}
