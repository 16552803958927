.field {
  margin-bottom: 20px;
}

.addAttributesBtn {
  background-color: transparent;
  border: 1px solid #2ecc71;
  border-radius: 5px;
  color: #2ecc71;
  font-size: 16px;
  display: inline-block;
  padding: 9px 10px;
  min-height: auto;
  margin-bottom: 30px;
  margin-top: 30px;
  width: auto;
  line-height: 19px;
}

.addAttributesBtn:hover {
  color: #fff;
  background-color: #2ecc71;
}

.field {
  width: 50%;
}

.fieldWrapper {
  display: flex;
}

.inputFields {
  width: 100%;
  margin-bottom: 0px;
  display: flex;
}

.removeBtnWrapper {
  padding: 0px 15px;
  align-self: flex-start;
  height: 100%;
  margin-top: 40px
}

.removeIcon {
  cursor: pointer;
}

.fieldSelect {
  width: 50%;
  margin-right: 10px;

  & input {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
  }
}

.freeInputField {
  margin-top: 10px;
}
